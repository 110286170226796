@mixin type-heading-96 {
	font-weight: 700;
	font-size: 96px;
	line-height: 100%;
	letter-spacing: -0.025em;
}

@mixin type-heading-44 {
	font-weight: 700;
	font-size: 44px;
	line-height: 109%;
	letter-spacing: -0.0225em;
}

@mixin type-heading-28 {
	font-weight: 700;
	font-size: 28px;
	line-height: 114%;
	letter-spacing: -0.025em;
}

@mixin type-heading-20 {
	font-weight: 700;
	font-size: 20px;
	line-height: 120%;
	letter-spacing: -0.025em;
}

@mixin type-heading-16 {
	font-weight: 500;
	font-size: 16px;
	line-height: 125%;
	letter-spacing: -0.0125em;
}

@mixin type-heading-12 {
	font-weight: 500;
	font-size: 12px;
	line-height: 133%;
	letter-spacing: -0.0125em;
}

@mixin type-body-20 {
	font-weight: 400;
	font-size: 20px;
	line-height: 120%;
	letter-spacing: -0.03em;
}

@mixin type-body-14 {
	font-weight: 400;
	font-size: 14px;
	line-height: 129%;
	letter-spacing: -0.03em;
}

@mixin type-detail-12 {
	font-weight: 700;
	font-size: 12px;
	line-height: 167%;
	letter-spacing: -0.01em;
	text-transform: uppercase;
}

@mixin type-detail-8 {
	font-weight: 700;
	font-size: 8px;
	line-height: 150%;
	letter-spacing: 0.2em;
	text-transform: uppercase;
}

.type-noto-sans-jp {
	font-family: 'Noto Sans JP', sans-serif;
}

.type-noto-sans-arabic {
	font-family: 'Noto Sans Arabic', sans-serif;
}

.type-detail-8 {
	@include type-detail-8;
}

h1 {
	@include type-heading-44;

	@media all and (max-width: $breakS) {
		@include type-heading-28;
	}
}

h2 {
	@include type-heading-44;

	br.mobile-break {
		display: none;
	}

	@media all and (max-width: $breakS) {
		@include type-heading-20;

		br.mobile-break {
			display: block;
		}
	}
}

h3 {
	@include type-heading-20;

	@media all and (max-width: $breakS) {
		@include type-heading-16;
		margin-bottom: 40px;
	}
}

h4 {
	@include type-heading-16;

	@media all and (max-width: $breakS) {
		@include type-heading-12;
	}
}