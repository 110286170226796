/* Page Setup + Mixins */

$breakXLNoUnit: 1440;
$breakXL: 1440px;
$breakL: 1024px;
$breakM: 950px;
$breakS: 768px;
$breakSNoUnit: 768;

:root {
  --gutter: 32px;
  --pageMarginTotal: 32px;
  --negPageMargin: calc(var(--pageMarginTotal) * -1);
  --fullBleedWidth: calc(100% + (var(--pageMarginTotal) * 2));
  --innerWidth: calc(100% - (var(--pageMarginTotal) * 2));
  --pageMargin: calc(var(--pageMarginTotal) - (var(--gutter) / 2));
  --easingOut: cubic-bezier(0.16, 1, 0.3, 1);
  --easing: cubic-bezier(0.83, 0, 0.17, 1);
  --av-black: #0F0F0F;
  --av-grey-80: #9B9B9B;
  --av-grey-60: #C2C2C2;
  --av-grey-40: #E0E0E0;
  --av-grey-20: #ECECEC;
  --av-white: #FFFFFF;
  --av-blue-20: #EBEEEF;
  --av-signal-red: #FF4127;
  --av-signal-red-hover: color-mix(in srgb, black 10%, #FF4127);
  --av-signal-red-active: color-mix(in srgb, black 20%, #FF4127);
  --av-signal-green: #24BA17;
  --av-signal-green-hover: color-mix(in srgb, black 10%, #24BA17);
  --av-signal-green-active: color-mix(in srgb, black 20%, #24BA17);
  --logoHeight: 44px;

  @media all and (max-width: $breakS) {
    --gutter: 16px;
    --logoHeight: 24px;
    --pageMarginTotal: 16px;
  }
}

@mixin fluidProp($prop, $min, $max) {
  #{$prop}: #{$min + "px"};
  $diff: (
    $max - $min
  );
$screenDiff: (
  $breakXLNoUnit - $breakSNoUnit
);

@media screen and (min-width: $breakS) {
  #{$prop}: calc(#{$min + "px"} + #{$diff} * ((100vw - #{$breakS}) / #{$screenDiff}));
}

@media screen and (min-width: $breakXL) {
  #{$prop}: #{$max + "px"};
}
}

@import "reset";
@import "grid";
@import "typography";

/* Colours */

.color-black {
  color: var(--av-black);
}

.color-grey-80 {
  color: var(--av-grey-80);
}

.color-grey-60 {
  color: var(--av-grey-60);
}

.color-grey-40 {
  color: var(--av-grey-40);
}

.color-grey-20 {
  color: var(--av-grey-20);
}

.color-white {
  color: var(--av-white);
}

.color-blue-20 {
  color: var(--av-blue-20);
}

.color-signal-red {
  color: var(--av-signal-red);
}

.color-signal-green {
  color: var(--av-signal-green);
}

.bg-black {
  background-color: var(--av-black);
}

.bg-grey-80 {
  background-color: var(--av-grey-80);
}

.bg-grey-60 {
  background-color: var(--av-grey-60);
}

.bg-grey-40 {
  background-color: var(--av-grey-40);
}

.bg-grey-20 {
  background-color: var(--av-grey-20);
}

.bg-white {
  background-color: var(--av-white);
}

.bg-blue-20 {
  background-color: var(--av-blue-20);
}

.bg-signal-red {
  background-color: var(--av-signal-red);
}

.bg-signal-green {
  background-color: var(--av-signal-green);
}

/* Spacers */

/* Grid Overlay */

.grid-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  .column-overlay {
    background: red;
    opacity: 0.1;
    height: 100vh;
  }
}

/* General Styles */

html {
  &.no-scroll {
    overflow: hidden;
  }
}

body {
  background-color: var(--av-white);
  color: var(--av-black);
  -webkit-font-smoothing: antialiased;
  font-family: 'Helvetica Now', 'Helvetica Neue', 'Helvetica', sans-serif;

  &.modal-open {

    main,
    header,
    footer {
      opacity: 0;
    }
  }
}

::selection {
  background: var(--av-signal-red);
  color: white;
}

p {
  @include type-body-20;

  @media all and (max-width: $breakS) {
    @include type-body-14;
  }
}

// p a,
// ul:not(.social-links) li a {
//   text-decoration: none;
//   color: var(--av-black);
//   border-bottom: 1px solid var(--av-grey-light);

//   &:hover {
//     border-color: var(--av-black);
//   }
// }

h2 {
  // @include type-large;
  margin-bottom: 18px;
}

header {
  position: relative;
  overflow: hidden;
  height: 100vh;
  height: 100svh;

  .marquee {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 25;

    @media all and (max-width: $breakS) {
      top: auto;
      bottom: 0;
    }
  }

  .header-content-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 20;
    color: var(--av-white);
    padding-bottom: var(--pageMarginTotal);

    @media all and (max-width: $breakS) {
      bottom: 40px;
    }

    span {
      opacity: .6;
    }
  }

  .header-logo {
    position: absolute;
    bottom: var(--pageMarginTotal);
    right: var(--pageMarginTotal);
    z-index: 25;

    @media all and (max-width: $breakS) {
      bottom: auto;
      top: var(--pageMarginTotal);
      right: auto;
      left: var(--pageMarginTotal);
    }
  }
}

.header-media {
  height: 100%;
  position: relative;
  z-index: 10;
  background: #e0e0e0;

  img,
  video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  video {
    object-fit: cover;
    width: 100%;
    object-position: 50% 50%;
    left: 0;
    right: 0;
  }

  .video-js {
    width: 100%;
    height: 100%;
  }
}

.video-js {
  background: #eee;

  .vjs-loading-spinner {
    opacity: 0;
  }
}

.signal {
  color: var(--av-signal-red);
}

.content-row {
  padding: 80px 0 100px 0;

  @media all and (max-width: $breakM) {
    padding: 80px 0;
  }

  @media all and (max-width: $breakS) {
    padding: 80px 0 0 0;
  }
}

.content-row-title-text {
  padding: 16px 0 100px 0;
  position: relative;

  @media all and (max-width: $breakM) {
    padding: 16px 0 80px 0;
  }

  &:before {
    content: "";
    display: block;
    width: calc(100% - (var(--pageMarginTotal) * 2));
    position: absolute;
    top: 0;
    left: var(--pageMarginTotal);
    height: 1px;
    background: var(--av-grey-light);
  }
}

.button {
  background: var(--av-signal-red);
  color: var(--av-white);
  padding: 12px 16px;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-style: normal;
  @include type-detail-12;
  line-height: 19px;
  text-decoration: none;
  --flow-space: 44px;

  .icon {
    font-size: 20px;
  }

  &.green {
    background: var(--av-signal-green);

    &:hover {
      background: var(--av-signal-green-hover);
    }

    &:active {
      background: var(--av-signal-green-active);
    }
  }

  &:hover {
    background: var(--av-signal-red-hover);
    color: var(--av-white);
  }

  &:active {
    background: var(--av-signal-red-active);
  }
}

.buttons {
  @media all and (max-width: $breakS) {
    display: flex;
  }

  .button {
    @media all and (max-width: $breakS) {
      flex: 1;
      margin: 0 5px;
    }
  }
}

.buttons+.type-details {
  margin-top: 50px;
}

footer {
  // margin-top: 60px;
  padding: var(--pageMarginTotal) 0;
  background-color: var(--av-signal-red);
  color: var(--av-white);
  min-height: 100lvh;
  display: flex;
  align-items: flex-end;

  ::selection {
    background: var(--av-white);
    color: car(--av-signal-red);
  }

  .columns {
    align-items: flex-end;
  }

  img {
    display: inline-block;
  }

  p {
    @include type-heading-44;

    @media all and (max-width: $breakS) {
      @include type-heading-28;
    }
  }
}

.media-block {
  position: relative;
  overflow: hidden;

  &.landscape {
    height: 0;
    padding-bottom: 66%;

    img,
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
      transition: 0.4s var(--easing);
    }
  }

  .top-right-icon {
    position: absolute;
    top: 22px;
    right: 22px;
    z-index: 5;
    font-size: 20px;
    transition: color 0.4s var(--easing);
  }
}

a:hover .media-block {
  .top-right-icon {
    color: var(--av-signal-red);
  }
}

.open-image {
  display: block;
  color: var(--av-black);
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: var(--gutter);
  transform: translate3d(0, 0, 0);

  @media all and (min-width: $breakS) {
    &:hover {
      .media-block {

        img,
        video {
          transform: translate3d(0, 0, 0) scale(1.1);
        }
      }
    }
  }

  &.light {
    color: white;
  }

  .video-js {
    width: 100%;
    height: 0;
    padding-bottom: 66%;
  }

  &.open {
    .top-right-icon {
      opacity: 0;
    }
  }
}

.full-screen-image {
  overflow: hidden;

  &.light {
    color: white;

    .close-fullscreen-image {
      color: white;
    }
  }

  &.animate {
    animation: goFullScreen 0.6s forwards var(--easing);
  }

  .close-fullscreen-image {
    position: absolute;
    top: 22px;
    right: 22px;
    font-size: 20px;
    z-index: 5;
    color: var(--av-black);
  }

  .media-block.landscape {
    height: 100%;
    padding-bottom: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transform: scale(1.1);
    animation: goFullScreenImg 0.6s forwards var(--easing);
  }

  &.close {
    animation: exitFullScreen 0.6s forwards var(--easing);
  }

  .top-right-icon {
    display: none;
  }
}

@keyframes goFullScreen {
  from {
    top: var(--start-top);
    left: var(--start-left);
    width: var(--start-width);
    height: var(--start-height);
    border-radius: 16px;
  }

  to {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

@keyframes exitFullScreen {
  from {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  to {
    top: var(--start-top);
    left: var(--start-left);
    width: var(--start-width);
    height: var(--start-height);
    border-radius: 16px;
  }
}

@keyframes goFullScreenImg {
  to {
    transform: scale(1);
  }
}

.modal-wrap {
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.4s var(--easing);
  align-items: center;
  justify-content: center;
  padding-top: calc((var(--pageMarginTotal) * 2) + var(--logoHeight));
  padding-bottom: var(--pageMarginTotal);

  .modal {
    opacity: 0;
    transform: scale(0.9);
    transition: all 0.6s var(--easingOut);
  }

  &.show {
    opacity: 1;
    visibility: visible;
    pointer-events: all;

    .modal {
      opacity: 1;
      transform: scale(1);
    }
  }

  &.confirm-age-wrap {
    transition: none;

    .modal {
      transition: none;
    }
  }

  .before-confirm,
  .before-order {
    display: block;
  }

  .after-rejection,
  .after-order {
    display: none;
  }

  &.rejected {
    .before-confirm {
      display: none;
    }

    .after-rejection {
      display: block;
    }
  }

  &.ordered {
    .before-order {
      display: none;
    }

    .after-order {
      display: block;
    }
  }
}

.modal {
  margin: auto;
  position: relative;
  z-index: 2;
  background: rgba(255, 255, 255, .05);
  color: var(--av-white);
  border-radius: 16px;
  padding: 20px 0 16px 0;
  width: calc(100% - (var(--pageMarginTotal) * 2));
  height: calc(100dvh - (var(--pageMarginTotal) * 3) - var(--logoHeight));
  display: flex;
  flex-direction: column;
  row-gap: var(--gutter);
  justify-content: space-between;
  @include type-heading-28;
  overflow-y: auto;


  p {
    @include type-heading-28;

    @media all and (max-width: $breakS) {
      @include type-heading-12;
    }
  }

  .share-module {
    margin-top: 70px;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, .2);

    @media all and (max-width: $breakS) {
      margin-top: 20px;
    }

    p {
      @include type-heading-20;
    }

    .social-links .button {
      width: 44px;
      height: 44px;
      font-size: 20px;
      padding: 0;
      align-items: center;
      gap: 0;
      justify-content: center;

      .icon {
        margin: 0;
      }
    }
  }

  .columns {
    padding: 0 20px;

    .columns {
      padding: 0;
    }
  }

  &.slider-modal {
    background-color: transparent;
    border-radius: 0;
    padding: 0;

    .top {
      height: calc(100% - 120px - var(--gutter));
      overflow: hidden;

      @media all and (max-width: $breakS) {
        height: calc(100% - 70px);
        padding-top: 20px;
      }

      .media-slider {
        height: 100%;

        .media-slide {
          height: 100%;
          width: 100%;
          margin-right: var(--pageMarginTotal);

          @media all and (max-width: $breakS) {
            overflow: hidden;
            border-radius: 4px;
          }

          img,
          video {
            width: 100%;
            height: 100%;
            object-fit: contain;

            @media all and (max-width: $breakS) {
              object-fit: cover;
            }
          }
        }
      }
    }

    .bottom {
      height: 120px;
      display: flex;
      gap: var(--gutter);
      align-items: flex-end;

      @media all and (max-width: $breakS) {
        height: 50px;
      }

      .left {
        width: 190px;

        @media all and (max-width: $breakS) {
          position: absolute;
          bottom: 100%;
          left: 0;
        }
      }

      .right {
        width: calc(100% - 190px - var(--gutter));

        @media all and (max-width: $breakS) {
          width: 100%;
        }
      }

      p {
        @include type-heading-20;

        @media all and (max-width: $breakS) {
          @include type-heading-16;
        }
      }

      .media-slider-nav {
        .media-slide {
          width: 85px;
          height: 56px;
          border-radius: 4px;
          overflow: hidden;
          margin-left: 16px;
          opacity: .2;
          cursor: pointer;

          @media all and (max-width: $breakS) {
            width: 77px;
            height: 50px;
          }

          &:hover {
            opacity: .3;
          }

          &.is-selected {
            opacity: 1;
          }

          img,
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .media-slider-meta {
        .counter {
          color: color-mix(in srgb, var(--av-white) 25%, var(--av-black));

          .current {
            color: var(--av-white);
          }
        }
      }
    }
  }
}

.close-modal {
  position: absolute;
  top: var(--pageMarginTotal);
  right: var(--pageMarginTotal);
  font-size: 20px;
  color: var(--av-white);
  z-index: 3;
  background-color: rgba(255, 255, 255, .2);
  width: 44px;
  height: 44px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .4s var(--easingOut);

  @media all and (max-width: $breakS) {
    width: 24px;
    height: 24px;
    font-size: 16px;
    right: calc(var(--pageMarginTotal) + 20px);
  }

  &:hover {
    background-color: rgba(255, 255, 255, .3);
    color: var(--av-white);
  }

  &:active {
    background-color: rgba(255, 255, 255, .14);
    color: var(--av-white);
  }
}

.press-media-wrap {
  .modal {
    overflow: visible;
  }

  .close-modal {
    @media all and (max-width: $breakS) {
      right: var(--pageMarginTotal);
    }
  }
}

.modal-logo {
  position: absolute;
  top: var(--pageMarginTotal);
  left: calc(var(--pageMarginTotal) + 20px);
  z-index: 2;

  img {
    widthL: auto;
    height: var(--logoHeight);
  }
}

.modal-bg {
  background: var(--av-black);
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.form-wrap {
  margin-top: 70px;
  padding-top: 40px;
  border-top: 1px solid rgba(255, 255, 255, .2);

  @media all and (max-width: $breakS) {
    margin-top: 20px;
    padding-top: 20px;
  }
}

form {

  label {
    color: var(--av-grey-80);
    display: block;
    // margin-bottom: 12px;
    @include type-detail-8;

    @media all and (max-width: $breakS) {
      transform: translateY(50%);
      pointer-events: none;
    }
  }

  input[type="email"],
  input[type="text"] {
    @include type-heading-28;
    display: block;
    width: 100%;
    appearance: none;
    border-radius: 0;
    padding: 20px 0;
    border: 0;
    outline: 0;
    border-bottom: 1px solid rgba(255, 255, 255, .4);
    background-color: transparent;
    color: inherit;

    @media all and (max-width: $breakS) {
      @include type-heading-20;
    }

    &::placeholder {
      color: var(--av-grey-80);
    }

    &:focus {
      border-bottom: 1px solid rgba(255, 255, 255, .4);
    }

    &:placeholder-shown:not(:focus) {
      border-bottom: 1px solid rgba(255, 255, 255, .2);
    }
  }

  input[type="submit"].button {
    outline: 0;
    border: 0;
    appearance: none;
    cursor: pointer;
    margin-top: 24px;
  }

  #mce-responses .response {
    padding-top: 8px;
    @include type-detail-8;
    margin-top: -1px;
  }

  #mce-error-response {
    color: var(--av-signal-red);
    border-top: 1px solid var(--av-signal-red);
  }

  #mce-success-response {
    color: var(--av-signal-green);
    border-top: 1px solid var(--av-signal-green);
  }

  // #mce-responses #mce-error-response {
  //   @include type-details;
  // }
}

.social-links {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  li {
    margin-right: 8px;
  }

  // a {
  //   text-decoration: none;
  //   font-size: 24px;
  //   line-height: 0;
  //   color: white;
  //   display: block;
  //   background-color: var(--av-signal-red);
  //   padding: 8px 24px;
  //   border-radius: 24px;

  //   &:hover {
  //     background-color: var(--av-black);
  //   }
  // }
}

.options {
  --flow-space: 8px;

  a {
    @include type-heading-96;
    color: var(--av-signal-red);
    text-decoration: none;
    display: block;
    padding: 8px 0 12px 0;
    border-top: 1px solid rgba(255, 255, 255, .1);

    &:last-child {
      padding-bottom: 0;
    }

    @media (hover: hover) {
      &:hover {
        color: var(--av-white);
      }
    }

    @media all and (max-width: $breakS) {
      @include type-heading-28;
    }
  }
}

.marquee {
  --gap: 24px;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  padding: 0 var(--gap);
  background-color: var(--av-black);
  color: var(--av-white);
}

.marquee__content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  min-width: 100%;
  gap: var(--gap);
  align-items: center;
  animation: scroll 20s linear infinite;
  @include type-detail-12;
  padding: 10px 0;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

section {
  padding: 20px 0 200px 0;

  @media all and (max-width: $breakS) {
    padding: 20px 0 48px 0;
  }

  &.no-padding {
    padding: 20px 0;
  }

  &.padding-bottom-reduced {
    padding-bottom: 120px;

    @media all and (max-width: $breakS) {
      padding: 20px 0 48px 0;
    }
  }

  &.border-top {
    border-top: 1px solid var(--av-grey-20);
  }
}

.fill-on-scroll {
  color: var(--av-grey-40);

  .char {
    --appearPerc: calc((1.2 + ((var(--viewport-y) * 2.5) - var(--char-percent))) * 100 * 1%);
    color: color-mix(in srgb, var(--av-black) clamp(0%, var(--appearPerc), 100%), var(--av-grey-40));
  }

  .color-signal-green {
    .char {
      color: color-mix(in srgb, var(--av-signal-green) clamp(0%, var(--appearPerc), 100%), var(--av-grey-40));
    }
  }

  @media all and (max-width: $breakS) {
    padding-right: 38px;
  }
}

.fill-on-scroll+.fill-on-scroll {
  margin-top: 45px;
}

.diseases {
  margin-top: 12px;

  .disease-row {
    display: grid;
    gap: var(--gutter);
    grid-template-columns: calc(((100vw - (var(--gutter) * 11) - (var(--pageMarginTotal) * 2)) / 12)) 1fr;
    align-items: baseline;
    border-top: 1px solid var(--av-grey-20);
    padding-top: 8px;

    @media all and (max-width: $breakS) {
      grid-template-columns: 18px 1fr;
    }

    &:last-child {
      border-bottom: 1px solid var(--av-grey-20);
      padding-bottom: 12px;
    }

    .left {

      img {
        display: inline-block;
        width: auto;
        max-height: 68px;

        @media all and (max-width: $breakS) {
          max-height: 19px;
        }
      }
    }

    .right {
      flex: 1;
      color: var(--av-signal-red);

      p {
        @include type-heading-96;
        @include fluidProp("font-size", 28, 98);

        @media all and (max-width: $breakS) {
          @include type-heading-28;
        }
      }

    }
  }

  .disease-row+.disease-row {
    margin-top: 12px;
  }
}

.appear {
  transition: transform .8s var(--easingOut), opacity .8s var(--easingOut);
  transition-delay: calc(var(--i) * 0.08s);
  transform: translateY(20px);
  opacity: 0.2;

  &.has-been-seen {
    transform: none;
    opacity: 1;
  }
}

.media {
  position: relative;
  overflow: hidden;

  &:not(.natural) {

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.square {
    aspect-ratio: 1;
  }

  &.portrait {
    aspect-ratio: 87 / 107;
  }

  &.landscape {
    aspect-ratio: 720 / 419;
  }

  &.press-image {
    aspect-ratio: 437 / 289;
  }

  &.natural {

    img,
    video {
      width: 100%;
      height: auto;
    }
  }

  &.rounded {
    border-radius: 16px;
    transform: translateZ(0);
  }
}

.scroll-animation {
  height: 100vh;
  height: 100vh;

  #bottle-sequence {
    width: 100%;
    height: 100vh;
    height: 100vh;
  }
}

.section-title {
  padding-bottom: 61px;
  border-bottom: 1px solid var(--av-grey-20);
  margin-bottom: 16px;

  @media all and (max-width: $breakS) {
    padding-bottom: 10px;
  }
}

a {
  color: inherit;
  text-decoration-color: var(--av-grey-20);
  text-underline-offset: 5px;
  text-decoration-thickness: 1px;

  &:hover {
    // text-decoration-color: var(--av-signal-red);
    color: var(--av-signal-red);
  }
}

.disease-illustrations {
  display: grid;
  grid-template-columns: repeat(3, calc((100% - var(--gutter) * 2) / 3));
  column-gap: var(--gutter);
  justify-items: stretch;
  row-gap: 48px;
  margin-top: 80px;

  @media all and (max-width: $breakS) {
    grid-template-columns: repeat(2, calc((100% - var(--gutter) * 1) / 2));
  }

  .disease-illustration-card {
    display: flex;
    flex-direction: column;

    .card-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .disease-illustration {
      background-color: var(--av-blue-20);
      border-radius: 16px;
      transform: translateZ(0);
      overflow: hidden;
      position: relative;
      aspect-ratio: 1 / 1;
      margin-bottom: 16px;

      .illustration,
      .triangle {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        z-index: 5;
      }

      .illustration {
        opacity: 0;
        transform: scale(1);
        transition: transform .4s var(--easingOut), opacity .4s var(--easingOut);
        transition-delay: calc(.1s + var(--i) * 0.08s);
      }

      .triangle {
        z-index: 10;
        opacity: 0;
        transform: scale(1);
        transition: transform .4s var(--easingOut), opacity .4s var(--easingOut);
        transition-delay: calc(.6s + var(--i) * 0.08s);
      }

      &.has-been-seen {
        .illustration {
          opacity: 1;
          transform: scale(1);
        }

        .triangle {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}

.slider {
  .full-width-image {
    width: 100%;
  }

  .flickity-page-dots {
    text-align: left;
    bottom: 32px;
    padding-left: var(--pageMarginTotal);

    .dot {
      background-color: var(--av-black);
      margin: 0;
      width: 8px;
      height: 8px;
      margin-right: 8px;
      opacity: .16;

      &:not(.is-selected) {
        &:hover {
          opacity: 1;
          background-color: var(--av-signal-green);
        }
      }

      &.is-selected {
        opacity: 1;
      }
    }
  }
}

.slider+* {
  margin-top: 16px;
}

.news-articles {
  margin-top: 80px;

  @media all and (max-width: $breakS) {
    margin-top: 32px;
  }

  article {
    border-top: 1px solid var(--av-grey-20);

    &.hide {
      display: none;
    }

    .details {
      color: var(--av-grey-60);
      display: flex;
      gap: 32px;
      margin-bottom: 16px;
    }

    .news-title {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      .left {
        width: calc(100% - 40px);
      }

      .right {
        width: 40px;
        text-align: right;
      }

      .icon {
        font-size: 24px;
      }
    }

    h3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media all and (max-width: $breakS) {
        margin-bottom: 0;
      }
    }

    a {
      display: block;
      padding: 16px 0;
      text-decoration: none;
      transition: all .2s var(--easingOut);

      &:hover {
        color: var(--av-signal-red);
      }
    }
  }
}

.load-more-news {
  width: 44px;
  height: 44px;
  background-color: var(--av-signal-red);
  color: var(--av-white);
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin-top: 8px;

  &:hover {
    background: var(--av-signal-red-hover);
    color: var(--av-white);
  }

  &:active {
    background: var(--av-signal-red-active);
  }
}

.press-media {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gutter);
  margin-top: 120px;

  @media all and (max-width: $breakM) {
    grid-template-columns: repeat(2, 1fr);
  }

  .press-media-card {
    .media {
      img {
        transition: all 1s var(--easingOut);
        transform: translate3d(0, 0, 0);
      }
    }

    &:hover {
      .media {
        img {
          transform: translate3d(0, 0, 0) scale(1.05);
        }
      }
    }
  }
}

.fancybox__container {
  --fancybox-color: var(--av-white);
  --fancybox-bg: var(--av-black);
  --fancybox-toolbar-text-shadow: none;
  --fancybox-opacity: 1;
  --fancybox-hover-color: var(--av-signal-red);

}

.fancybox__nav {
  --f-button-next-pos: var(--pageMarginTotal);
  --f-button-prev-pos: var(--pageMarginTotal);
  --f-button-shadow: none;
  --f-button-hover-bg: transparent;
  --f-button-active-bg: transparent;
  --f-button-svg-filter: none;
  --f-button-svg-stroke-width: 0;
  --f-button-svg-fill: var(--av-white);
  --f-button-svg-width: 37px;
  --f-button-svg-height: 37px;
}

.fancybox__thumbs {
  --f-thumb-border-radius: 4px;
  --f-thumb-outline: 0;
  --f-thumb-width: 85px;
  --f-thumb-height: 56px;

  &.is-classic {
    --f-thumb-gap: 16px;
    --f-thumb-opacity: .2;
  }
}

.fancybox__toolbar {
  font-family: 'Helvetica Now', 'Helvetica Neue', 'Helvetica', sans-serif;
}

.fancybox__infobar {
  position: absolute;
  bottom: 28px;
  left: var(--pageMarginTotal);
  padding: 0;
  color: color-mix(in srgb, var(--av-white) 25%, var(--av-black));
  text-align: left;

  @include type-body-20;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;

  p {
    @include type-body-20;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
  }

  [data-fancybox-current-index] {
    color: var(--av-white);
    padding-left: 0;
  }

  @media all and (max-width: $breakS) {
    position: static;
  }
}



// .fancybox__thumbs.is-horizontal {
//   padding-left: 205px;
//   padding-right: 0;

//   @media all and (max-width: $breakS) {
//     padding-left: var(--f-thumb-gap);
//   }
// }

// .f-thumbs__viewport {
//   overflow-x: scroll;
// }

.f-thumbs.is-horizontal .f-thumbs__track {
  padding: 32px 0;
  // transform: none !important;
  // justify-content: flex-end;
}

.fancybox__toolbar {
  --f-button-bg: transparent;
  --f-button-hover-bg: transparent;
  --f-button-active-bg: transparent;
  --f-button-svg-width: 34px;
  --f-button-svg-height: 34px;
  --f-button-svg-filter: none;
  padding: 26px var(--pageMarginTotal);
}

.fancybox__caption {
  padding-top: 28px;
  font-size: 14px;
  line-height: 130%;
}

.fancybox__caption {
  position: absolute;
  bottom: 0;
  min-height: 70px;
  z-index: 20;
  background-color: var(--av-white);
  text-align: center;
  padding: 28px var(--pageMarginTotal);
  left: 0;
  width: 100%;
}

// .fancybox__slide.has-image>.fancybox__content {
//   padding-bottom: 65px;
// }

.fancybox__slide.has-image.has-caption {
  padding-bottom: 70px;

  @media all and (max-width: $breakS) {
    padding-bottom: 0;
  }
}

.fancybox__slide.has-image {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 10px;

  @media all and (max-width: $breakS) {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.fancybox__slide.has-image:not(.has-caption) {
  padding-bottom: 25px;
}

@media (hover: hover) {
  .f-button:hover:not([disabled]) {
    svg {
      fill: var(--f-button-hover-color);
    }
  }
}

.f-button {
  font-size: 20px;
  color: var(--av-white);
  background-color: rgba(255, 255, 255, .2);
  width: 44px;
  height: 44px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .4s var(--easingOut);

  --f-button-svg-width: 16px;
  --f-button-svg-height: 16px;
  --f-button-hover-color: var(--av-white);
  --f-button-hover-bg: rgba(255, 255, 255, .3);
  --f-button-active-bg: rgba(255, 255, 255, .16);

  @media all and (max-width: $breakS) {
    width: 24px;
    height: 24px;
    font-size: 16px;
    --f-button-svg-width: 12px;
    --f-button-svg-height: 12px;
  }
}

.fixed-image {
  height: 100lvh;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: inset(0);

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    opacity: calc(var(--viewport-y) * -1);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  }

  .bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100lvh;
    z-index: 1;

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.reached-top {
    .bg {
      position: absolute;
    }
  }
}

.full-width-video-wrap {
  position: relative;

  .video-thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--gutter);
    padding: 28px 0 32px 0;
    z-index: 10;

    @media all and (max-width: $breakS) {
      padding: 16px 0;
    }

    .top,
    .bottom {
      position: relative;
      z-index: 5;
      transition: all .2s var(--easingOut);
    }

    .top {
      color: var(--av-white);
      pointer-events: none;

      p {
        @include type-heading-44;

        @media all and (max-width: $breakS) {
          @include type-heading-20;
        }
      }

      span {
        opacity: .6;
      }
    }

    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all .2s var(--easingOut);
      cursor: pointer;

      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .play-pause-movie {
      font-size: 20px;
      color: var(--av-white);
      background-color: rgba(255, 255, 255, .3);
      width: 44px;
      height: 44px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .4s var(--easingOut);

      &:hover {
        background-color: rgba(255, 255, 255, .4);
        color: var(--av-white);
      }

      &:active {
        background-color: rgba(255, 255, 255, .2);
        color: var(--av-white);
      }
    }
  }

  .full-width-video {
    video {
      display: block;
      width: 100%;
    }
  }

  &.playing {
    .video-thumbnail {
      .top {
        opacity: 0;
      }

      .bg {
        opacity: 0;
      }
    }
  }
}